import * as Api from '@/api/course/course'
import ClassSchedule from '@/components/ClassSchedule/ClassSchedule.vue'
import CourseMaterial from './Materials.vue'
import Comment from '@/components/Comment/Comment.vue'
import { translateTag, formatMoment, openPage, openLiveRoom } from '@/utils/utils'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      // activeName: 'material',
      activeName: 'schedule',
      courseDetailType: 1,
      classSchedule: [],
      CourseMaterialData: [],
      videoUrl: ''
    }
  },
  computed: {
    coursePlanTaskId () {
      return this.$route.params.id || ''
    },
      ...mapState({
          courseDesc: state => state.course.currentCourseDesc
      })
  },
  mounted () {
    this.getCourseDetail()

  },
  methods: {
    formatMoment,
    openPage,
    openLiveRoom,
    getCourseDetail () {
      Api.classCourse('', (res) => {
        console.log(res)
        this.classSchedule = res
      }, this.coursePlanTaskId)
    },
    handleClick (item) {
      this.activeName = item
      switch (item) {
        case 'schedule':
          this.getCourseDetail()
          break
        case 'comment':
          break
        case 'materials':
          Api.courseMaterials(this.coursePlanTaskId, (res) => {
            this.CourseMaterialData = res
          })
      }
    },
    translateTag,
    enterLive(item) {
      let _this = this
      openLiveRoom(`/live/${item.id}`,2,item.id, this.$route, _this)
    },
    playLiveVideo(item) {
      if (!item.video_url) {
        this.$message({
          message: '此视频未上传，请联系授课老师~',
          type: 'error'
        })
        return
      }
      // window.open(item.video_url, '_blank')
      this.videoUrl = item.video_url
    },
    isGreaterTime(time) {
        return new Date().getTime()/1000 >= time
    },
    contextmenu (e) {
      console.log(e)
    //   禁用右键菜单
        e.preventDefault()
    }
  },
  components: {
    ClassSchedule,
    CourseMaterial,
    Comment
  }
}
