<template>
  <div class="class_page">
    <div class="course_overview" v-if="courseDesc.course">
      <div class="overview_con">
        <div class="overview_title">
            <span class="category_tag"
                  v-if="translateTag(courseDesc.course.category_id).abbr!=undefined">{{
                translateTag(courseDesc.course.category_id).abbr
              }}</span>
          <span class="type_tag">直播</span>
          <span class="title">{{ courseDesc.course.name }}</span>
        </div>
        <div class="overview_desc">
          <div class=""><span class="key">直播安排</span> <span
                  class="value">{{
              formatMoment(courseDesc.plan_started_at, 'YYYY/MM/DD')
            }} - {{ formatMoment(courseDesc.plan_ended_at, 'YYYY/MM/DD') }}</span>
            <span class="value">共{{ courseDesc.total_period }}节</span>
          </div>
          <div class=""><span class="key">已完成课时/剩余课时</span> <span
                  style="color:#29d087"> {{ courseDesc.finished_period }}</span><span class="value">/{{
              courseDesc.total_period
              - courseDesc.finished_period
            }}</span>
          </div>
          <div class=""><span class="key">有效期至</span><span
                  class="value">{{ formatMoment(courseDesc.expired_at, 'YYYY/MM/DD') }}</span>
          </div>
          <div class=""><span class="key">下次上课时间</span><span
                  class="value">{{
              formatMoment(courseDesc.next_started_at, 'YYYY/MM/DD')
            }} {{
              formatMoment(courseDesc.next_started_at, 'HH:mm')
            }} - {{ formatMoment(courseDesc.next_ended_at, 'HH:mm') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="course_detail">
      <div class="tab_class">
        <div class="tab_name_box">
          <div class="tab_name_item" :class="activeName === 'schedule'?'tab_name_item_active':''"
               @click="handleClick('schedule')">课程表
          </div>
          <div class="tab_name_item" :class="activeName === 'materials'?'tab_name_item_active':''"
               @click="handleClick('materials')">课程资料
            <div class="tips_box">
              <span class="tips">受服务器限制，每个文件可下载5次，请在下载后及时妥善保存哦！</span>
              <i class="iconfont icon-papa-tip"></i>
            </div>
          </div>
        </div>
        <div class="tab_body_con">
          <template v-if="activeName === 'schedule'">
            <el-table
                    :data="classSchedule"
                    style="width: 100%"
                    :show-header="false">
              <el-table-column
                      type="expand"
                      label=""
                      prop="title"
              >
                <template slot-scope="props">
                  <div class="schedule_item" v-for="item in props.row.children" :key="item.id">
                    <div class="item_title" v-if="item.course_mode === 1&&!item.is_recorded"><i
                            class="iconfont icon-papa-recording"></i> {{ item.title }}
                    </div>
                    <div class="item_title" v-if="item.course_mode === 1&&item.is_recorded"><i
                            class="iconfont icon-papa-recording"></i> {{ item.title }}
                    </div>
                    <div class="item_title" v-if="item.course_mode === 3"><i class="iconfont icon-papa-vedio"></i>
                      {{ item.title }}
                    </div>
                    <div class="item_time">{{ formatMoment(item.started_at, 'YYYY/MM/DD') }}
                      {{ formatMoment(item.started_at, 'HH:mm') }} - {{ formatMoment(item.ended_at, 'HH:mm') }}
                    </div>

                    <template v-if="item.course_mode=== 3">
                      <div class="item_status item_status--green" >录播课 </div>
                    </template>
                    <template v-else>
                      <div class="item_status item_status--green" v-if="item.status===1">待上课</div>
                      <div class="item_status item_status--gray" v-if="item.status===2&&item.is_recorded">直播结束</div>
                      <template v-if="item.status===2&&!item.is_recorded">
                        <div class="item_status item_status--gray" v-if="item.is_online_course">回放生成中</div>
                        <div class="item_status item_status--gray" v-else>已完成</div>
                      </template>
                      <div class="item_status item_status--gray" v-if="item.status===3">已取消</div>
                    </template>

                   <div>
                     <div class="button button--small button--green" v-if="item.course_mode=== 1&&item.status === 1"
                          @click="enterLive(item)">
                       进入直播
                     </div>
                     <div class="button button--small button--green" v-if="item.course_mode=== 3&&item.status === 1&&isGreaterTime(item.started_at)" @click="playLiveVideo(item)">
                       观看视频
                     </div>

                     <div class="button button--small button--green" v-if="item.status === 2 && item.is_recorded"
                          @click="openPage(`/course/replay/${item.id}`)">观看回放
                     </div>
                     <div class="button button--small button--green button--disable"
                          v-if="item.status === 2 && !item.is_recorded">观看回放
                     </div>
                     <div class="button button--small button--green " v-if="item.status === 3&&item.is_recorded"
                          @click="openPage(`/course/replay/${item.id}`)">观看回放
                     </div>
                     <div class="button button--small button--green button--disable"
                          v-if="item.status === 3&&!item.is_recorded">进入直播
                     </div>
                   </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                      label="描述"
                      prop="title">
              </el-table-column>

            </el-table>
          </template>
          <CourseMaterial v-else :materials="CourseMaterialData"></CourseMaterial>
        </div>
      </div>
    </div>
    <div class="play-box" v-if="videoUrl">
      <div class="video-box">
        <i class="close-video el-icon-close" @click="videoUrl = ''"></i>
        <video  preload="auto" controls controlslist="nodownload" disablepictureinpicture @contextmenu="contextmenu">
          <source :src="videoUrl" type="video/mp4">
        </video>
      </div>

    </div>
  </div>
</template>

<script>
  import LiveClassDetailJS from './LiveClassDetail.js'

  export default LiveClassDetailJS
</script>

<style lang="less" scoped>
  .class_page {
    width: 100%;
    height: 100%;

    .tab_class {
      .tab_name_box {
        user-select: none;
        width: 100%;
        height: 80px;

        .tab_name_item {
          float: left;
          font-size: 18px;
          color: rgba(0, 0, 0, 0.7);
          margin-right: 78px;
          position: relative;
          cursor: pointer;

          .tips_box {
            position: absolute;
            right: 5px;
            top: 6px;
          }

          .icon-papa-tip {
            position: absolute;
            left: 10px;
            right: 0;
            top: 0;
            color: #29D087;
          }

          .tips_box:hover {
              .tips{
                display: block;
              }
          }

          .tips {
            display: none;
            position: absolute;
            top: -42px;
            left: -200px;
            width: 400px;
            padding: 2px 11px;
            height: 24px;
            background: #EBEBEB;
            border-radius: 16px 16px 16px 16px;
            opacity: 1;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.6);
            line-height: 24px;
          }

          .tips::after {
            content: '';
            width: 0;
            height: 0;
            border-top: 10px solid #EBEBEB;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            position: absolute;
            bottom: -10px;
            left: 222px;
          }
        }

        .tab_name_item_active {
          position: relative;
          color: rgba(0, 0, 0, 0.9);
          font-weight: bold;

        }

        .tab_name_item_active::after {
          position: absolute;
          bottom: -18px;
          left: 50%;
          margin-left: -12px;
          content: '';
          width: 24px;
          height: 8px;
          background: #29D087;
          border-radius: 20px 20px 20px 20px;
          opacity: 1;
        }

      }

      .tab_body_con {
        width: 100%;
        min-height: 100vh;
      }
    }
  }

  .course_overview {
    width: 100%;
    box-sizing: border-box;
    margin: 36px auto 20px;
    height: 116px;

    .overview_con {
      width: 1200px;
      margin: 0 auto;
      padding: 25px 20px;
      box-sizing: border-box;
      background: #fff;
      border-radius: 28px;
    }

    .overview_title {
      display: flex;
      align-items: center;

      .category_tag {
        background: #667482;
        color: #fff;
        padding: 1px 4px;
        margin-right: 8px;
        font-size: @fontSizeCon;
      }

      .type_tag {
        padding: 1px 4px;
        margin-right: 8px;
        background: #DEF7E6;
        color: #20A84C;
        font-size: @fontSizeCon;
      }

      .title {
        color: #333;
        font-size: 26px;
        font-weight: bold;
      }
    }

    .overview_desc {
      margin-top: 10px;
      display: flex;

      div {
        margin-right: 40px;
      }

      .desc_teacher {
        color: #333;
      }

      .key {
        color: #999;
        margin-right: 1em;
      }

      .value {
        color: #555;
        font-weight: bold;
      }
    }
  }

  .course_detail {
    padding: 20px;
    width: 1200px;
    box-sizing: border-box;
    height: calc(100% - 186px);
    margin: 0 auto;
    background: #fff;
    border-radius: 28px;
  }


  .list_top {
    width: 100%;
  }

  /deep/ .schedule_item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    line-height: 58px;
    border-bottom: 1px solid #e6e6e6;
    box-sizing: border-box;

    > div {
      flex: 1;
    }

    .item_title, .item_time, .item_status {
      float: left;
      text-align: left;
      color: #333;
      font-size: 12px;

    }

    .item_title {
      width: 350px;
    }

    .item_time {
      width: 300px;
    }

    .item_status {
      width: 200px;
    }

    .item_status--green {
      color: @papaGreen;
    }

    .item_status--gray {
      color: #ccc;
    }

    .button {
      //float: right;
      cursor: pointer;
      margin-top: 11px;
      width: 90px;
      height: 36px;
      line-height: 36px;
      transition: all 0.3s;
      &:hover {
        color: #fff;
        background: #29D087;
      }
    }

  }

  /deep/ .el-table .cell {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }

  /deep/ .schedule_item:hover {
    /*background: #f5f7fa*/
  }

.play-box {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  .video-box {
    position: relative;
    width: 100vw;
    height: 100vh;
    video {
      width: 100%;
      height: 100%;
      background: #000;
    }
    .close-video {
      position: absolute;
      z-index: 9999;
      top: 20px;
      right: 20px;
      font-size: 30px;
      color: #979797;
      cursor: pointer;
      border-radius: 4px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    }
  }

}
</style>
